import { createSlice } from '@reduxjs/toolkit'

import { data } from '../../../static/db.json'

const dataInitialState = {
  timezone: data.timezone,
  worries: data.worries,
  wellBeing: data.wellBeing,
  theme: data.theme,
  metter: data.metter
}

export const slice = createSlice({
  name: 'data',
  initialState: dataInitialState,
  reducers: {
    getData: (state, { payload }) => {
      state.timezone = payload.timezone
      state.worries = payload.worries
      state.wellBeing = payload.wellBeing
      state.theme = payload.theme
      state.metter = payload.metter
    }
  }
})

const { getData } = slice.actions

export const loadAllData = () => async (dispatch) => {
  dispatch(getData(data))
}

export const selectData = (state) => state.data

export default slice.reducer
