import qs from 'qs'
import { isBrowser } from './root'
import { omit, pick } from 'ramda'

const KEY_LOCAL_STORAGE = 'initial_url_params'

export const getInitialQueryParamsFromLStorage = () =>
  JSON.parse(localStorage.getItem(KEY_LOCAL_STORAGE) ?? '{}')

export const setInitialQueryParams = (params) => {
  localStorage.setItem(KEY_LOCAL_STORAGE, JSON.stringify(params))
}

export const getInitialQueryParams = (params) =>
  isBrowser ? pick(params, getInitialQueryParamsFromLStorage()) : undefined

export const getInitialQueryParamsValue = (key) =>
  isBrowser ? getInitialQueryParamsFromLStorage()[key] : undefined

export const getUTM = () =>
  getInitialQueryParams([
    'utm_source',
    'utm_campaign',
    'utm_medium',
    'utm_term',
    'utm_content',
    'uid',
    'gclid',
    'click_id'
  ])

export const getInitialParamsHowSearchQuery = () =>
  isBrowser ? qs.stringify(getInitialQueryParamsFromLStorage()) : ''

export const clearInitialParams = (params) => {
  if (isBrowser) {
    setInitialQueryParams(omit(params, getInitialQueryParamsFromLStorage()))
  }
}
