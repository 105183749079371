// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-500-jsx": () => import("./../../../src/pages/500.jsx" /* webpackChunkName: "component---src-pages-500-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-act-terapiya-prinyatiya-i-otvetstvennosti-jsx": () => import("./../../../src/pages/act-terapiya-prinyatiya-i-otvetstvennosti.jsx" /* webpackChunkName: "component---src-pages-act-terapiya-prinyatiya-i-otvetstvennosti-jsx" */),
  "component---src-pages-apatiya-jsx": () => import("./../../../src/pages/apatiya.jsx" /* webpackChunkName: "component---src-pages-apatiya-jsx" */),
  "component---src-pages-application-form-jsx": () => import("./../../../src/pages/application-form.jsx" /* webpackChunkName: "component---src-pages-application-form-jsx" */),
  "component---src-pages-b-2-b-jsx": () => import("./../../../src/pages/b2b.jsx" /* webpackChunkName: "component---src-pages-b-2-b-jsx" */),
  "component---src-pages-be-in-team-jsx": () => import("./../../../src/pages/be-in-team.jsx" /* webpackChunkName: "component---src-pages-be-in-team-jsx" */),
  "component---src-pages-blog-index-jsx": () => import("./../../../src/pages/blog/index.jsx" /* webpackChunkName: "component---src-pages-blog-index-jsx" */),
  "component---src-pages-blog-mdx-slug-jsx": () => import("./../../../src/pages/blog/{mdx.slug}.jsx" /* webpackChunkName: "component---src-pages-blog-mdx-slug-jsx" */),
  "component---src-pages-contacts-jsx": () => import("./../../../src/pages/contacts.jsx" /* webpackChunkName: "component---src-pages-contacts-jsx" */),
  "component---src-pages-depressiya-jsx": () => import("./../../../src/pages/depressiya.jsx" /* webpackChunkName: "component---src-pages-depressiya-jsx" */),
  "component---src-pages-emdr-jsx": () => import("./../../../src/pages/emdr.jsx" /* webpackChunkName: "component---src-pages-emdr-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-form-jsx": () => import("./../../../src/pages/form.jsx" /* webpackChunkName: "component---src-pages-form-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-klient-tsentrirovannaya-psihoterapiya-jsx": () => import("./../../../src/pages/klient-tsentrirovannaya-psihoterapiya.jsx" /* webpackChunkName: "component---src-pages-klient-tsentrirovannaya-psihoterapiya-jsx" */),
  "component---src-pages-kognitivnii-psiholog-jsx": () => import("./../../../src/pages/kognitivnii-psiholog.jsx" /* webpackChunkName: "component---src-pages-kognitivnii-psiholog-jsx" */),
  "component---src-pages-kpt-kognitivno-povedencheskaia-psihoterapiia-jsx": () => import("./../../../src/pages/kpt-kognitivno-povedencheskaia-psihoterapiia.jsx" /* webpackChunkName: "component---src-pages-kpt-kognitivno-povedencheskaia-psihoterapiia-jsx" */),
  "component---src-pages-lichnii-psiholog-jsx": () => import("./../../../src/pages/lichnii-psiholog.jsx" /* webpackChunkName: "component---src-pages-lichnii-psiholog-jsx" */),
  "component---src-pages-narrativnaya-psihoterapiya-jsx": () => import("./../../../src/pages/narrativnaya-psihoterapiya.jsx" /* webpackChunkName: "component---src-pages-narrativnaya-psihoterapiya-jsx" */),
  "component---src-pages-navigate-jsx": () => import("./../../../src/pages/navigate.jsx" /* webpackChunkName: "component---src-pages-navigate-jsx" */),
  "component---src-pages-panicheskie-ataki-jsx": () => import("./../../../src/pages/panicheskie-ataki.jsx" /* webpackChunkName: "component---src-pages-panicheskie-ataki-jsx" */),
  "component---src-pages-podcast-jsx": () => import("./../../../src/pages/podcast.jsx" /* webpackChunkName: "component---src-pages-podcast-jsx" */),
  "component---src-pages-psiholog-seksolog-jsx": () => import("./../../../src/pages/psiholog-seksolog.jsx" /* webpackChunkName: "component---src-pages-psiholog-seksolog-jsx" */),
  "component---src-pages-psiholog-v-chate-jsx": () => import("./../../../src/pages/psiholog-v-chate.jsx" /* webpackChunkName: "component---src-pages-psiholog-v-chate-jsx" */),
  "component---src-pages-psihoterapevt-jsx": () => import("./../../../src/pages/psihoterapevt.jsx" /* webpackChunkName: "component---src-pages-psihoterapevt-jsx" */),
  "component---src-pages-razvod-jsx": () => import("./../../../src/pages/razvod.jsx" /* webpackChunkName: "component---src-pages-razvod-jsx" */),
  "component---src-pages-semeinyi-psiholog-jsx": () => import("./../../../src/pages/semeinyi-psiholog.jsx" /* webpackChunkName: "component---src-pages-semeinyi-psiholog-jsx" */),
  "component---src-pages-sertifikat-na-psihoterapiyu-jsx": () => import("./../../../src/pages/sertifikat-na-psihoterapiyu.jsx" /* webpackChunkName: "component---src-pages-sertifikat-na-psihoterapiyu-jsx" */),
  "component---src-pages-sposoby-oplaty-jsx": () => import("./../../../src/pages/sposoby-oplaty.jsx" /* webpackChunkName: "component---src-pages-sposoby-oplaty-jsx" */),
  "component---src-pages-tariff-jsx": () => import("./../../../src/pages/tariff.jsx" /* webpackChunkName: "component---src-pages-tariff-jsx" */),
  "component---src-pages-templates-catalog-jsx": () => import("./../../../src/pagesTemplates/catalog.jsx" /* webpackChunkName: "component---src-pages-templates-catalog-jsx" */),
  "component---src-pages-templates-psychologist-jsx": () => import("./../../../src/pagesTemplates/psychologist.jsx" /* webpackChunkName: "component---src-pages-templates-psychologist-jsx" */),
  "component---src-pages-travma-jsx": () => import("./../../../src/pages/travma.jsx" /* webpackChunkName: "component---src-pages-travma-jsx" */),
  "component---src-pages-trevojnost-jsx": () => import("./../../../src/pages/trevojnost.jsx" /* webpackChunkName: "component---src-pages-trevojnost-jsx" */),
  "component---src-pages-webinars-index-jsx": () => import("./../../../src/pages/webinars/index.jsx" /* webpackChunkName: "component---src-pages-webinars-index-jsx" */),
  "component---src-pages-webinars-mdx-slug-jsx": () => import("./../../../src/pages/webinars/{mdx.slug}.jsx" /* webpackChunkName: "component---src-pages-webinars-mdx-slug-jsx" */),
  "component---src-pages-wizard-jsx": () => import("./../../../src/pages/wizard.jsx" /* webpackChunkName: "component---src-pages-wizard-jsx" */)
}

