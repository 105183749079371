import { createSlice } from '@reduxjs/toolkit'

import { partners } from '../../../static/db.json'

const partnersLogoInitialState = {
  partners: []
}

export const slice = createSlice({
  name: 'partners',
  initialState: partnersLogoInitialState,
  reducers: {
    getLoad: (state, { payload }) => {
      state.partners = payload
    }
  }
})

const { getLoad } = slice.actions

export const loadPartnersLogo = () => async (dispatch) => {
  dispatch(getLoad(partners))
}

export const selectPartnersLogo = (state) => state.partners

export default slice.reducer
