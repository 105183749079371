import 'dayjs/locale/ru'
import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'

dayjs.locale('ru')
dayjs.extend(updateLocale)

dayjs.updateLocale('ru', {
  monthsShort: [
    'янв',
    'февр',
    'март',
    'апр',
    'май',
    'июнь',
    'июль',
    'авг',
    'сент',
    'окт',
    'нояб',
    'дек'
  ]
})
