import { setUrl } from '../routingSlice'

export default function errorHandleMiddleware({ dispatch }) {
  return (next) => (action) => {
    const returnValue = next(action)
    const servError = 'Error: 500'
    if (action.payload === servError) {
      console.log(action.payload)
      dispatch(setUrl())
      return returnValue
    }
    return returnValue
  }
}
