import * as amplitude from '@amplitude/analytics-browser'
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser'
import { useEffect } from 'react'

const API_KEY = process.env.GATSBY_AMPLITUDE_API_KEY

const amplitudeInit = () => {
  const sessionReplayTracking = sessionReplayPlugin({
    sampleRate: 0.03
  })

  amplitude.add(sessionReplayTracking)

  amplitude.init(API_KEY, {
    defaultTracking: {
      pageViews: false,
      sessions: true,
      formInteractions: false,
      fileDownloads: false
    }
  })
}

export const useInitAmplitude = () => useEffect(() => amplitudeInit(), [])
