import { createSlice } from '@reduxjs/toolkit'

const articleslInitialState = {
  cards: [],
  count: 0,
  tagsAll: []
}

export const slice = createSlice({
  name: 'articles',
  initialState: articleslInitialState,
  reducers: {
    getLoad: (state, { payload }) => {
      state.cards = payload.articles
      state.tagsAll = payload.tagsAll
      state.count = payload.count
    }
  }
})

const { getLoad } = slice.actions

export const loadArticles =
  ({ page = 1, limit = 9, tags = [] }) =>
  async (dispatch) => {
    const { articles } = articles
    const data = articles.filter((article, i) => {
      if (i < page * limit && i >= (page - 1) * limit) {
        return article
      }
    })
    if (tags.length) {
      const tagsData = data.filter((article) => tags.includes(article.tag))
      dispatch(getLoad(tagsData))
      return
    }
    dispatch(getLoad(data))
  }

export const selectArticles = (state) => state.articles

export default slice.reducer
