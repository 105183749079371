import { createSlice } from '@reduxjs/toolkit'

const routinglInitialState = {
  url: ''
}

export const slice = createSlice({
  name: 'routing',
  initialState: routinglInitialState,
  reducers: {
    setUrl: (state) => {
      state.url = '/500'
    },
    resetUrl: (state) => {
      state.url = ''
    }
  }
})

export const { setUrl, resetUrl } = slice.actions

export const selectUrl = (state) => state.routing.url

export default slice.reducer
