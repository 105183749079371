import './src/atoms/dayjsRuLocale'
import React, { createContext } from 'react'
import store from './src/state/store'
import { ApolloClientProvider } from './src/providers/Apollo/ClientProvider'
import { OrganizationMicromarkupComponent } from './src/atoms/SEO/Micromarkup/Organization'
import { Provider } from 'react-redux'
import { QueryClient, QueryClientProvider } from 'react-query'
import {
  getInitialQueryParamsFromLStorage,
  setInitialQueryParams
} from './src/atoms/initialQueryParams'
import { getQueryStringParams } from './src/helpers/queryParams'
import { pick } from 'ramda'
import { useInitAmplitude } from './src/components/amplitude/init'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 10 * 60 * 1000
    }
  }
})

const RootContext = createContext()

const RootContextProvider = ({ children }) => {
  useInitAmplitude()
  return <RootContext.Provider>{children}</RootContext.Provider>
}

export const wrapRootElement = ({ element }) => (
  <Provider store={store}>{element}</Provider>
)

export const wrapPageElement = ({ element }) => (
  <ApolloClientProvider>
    <QueryClientProvider client={queryClient}>
      <OrganizationMicromarkupComponent />
      <RootContextProvider>{element}</RootContextProvider>
    </QueryClientProvider>
  </ApolloClientProvider>
)

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === 'undefined') {
    await import('intersection-observer')
  }

  const url = getQueryStringParams(window.location.search)

  setInitialQueryParams({
    ...getInitialQueryParamsFromLStorage(),
    ...pick(
      [
        'utm_source',
        'utm_campaign',
        'utm_medium',
        'utm_term',
        'utm_content',
        'uid',
        'promo',
        'gclid',
        'click_id'
      ],
      url
    )
  })
}
