import { createSlice } from '@reduxjs/toolkit'

import { policy } from '../../../static/db.json'

const policyInitialState = {
  personalData: '',
  agreement: '',
  publicOffer: '',
  messangerHide: '0'
}

export const slice = createSlice({
  name: 'policy',
  initialState: policyInitialState,
  reducers: {
    getLoad: (state, { payload }) => {
      state.personalData = payload.ulrPersonalData
      state.agreement = payload.ulrAgreement
      state.publicOffer = payload.publicOffer
      state.messangerHide = payload.messangerHide
    }
  }
})

export const { getLoad } = slice.actions

export const loadPolicy = () => async (dispatch) => {
  dispatch(getLoad(policy))
}

export const selectPolicy = (state) => state.policy

export default slice.reducer
