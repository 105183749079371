import React from 'react'
import { JsonLd } from 'gatsby-plugin-next-seo'
import { useLocation } from '@reach/router'

const ORGANIZATION_DATA = {
  '@context': 'http://schema.org/',
  '@type': 'Organization',
  name: 'YouTalk',
  address: {
    '@type': 'PostalAddress',
    streetAddress: '2-я Бауманская д. 9/23, стр. 3, ком. 19',
    addressLocality: 'Москва',
    postalCode: '105005',
    addressCountry: 'Russian Federation'
  },
  email: 'mailto:care@youtalk.ru',
  telephone: '+7(495)165-87-80'
}

export const OrganizationMicromarkupComponent = () => {
  const location = useLocation()
  const jsonData = {
    ...ORGANIZATION_DATA,
    url: location.href,
    logo: `${location.origin}/img/icons/logo.svg`
  }
  return <JsonLd json={jsonData} />
}
