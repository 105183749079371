import { createSlice } from '@reduxjs/toolkit'

import { article } from '../../../static/db.json'

const articlelInitialState = {
  id: null,
  title: '',
  createdAt: '',
  announcement: '',
  content: '',
  img: '',
  tags: []
}

export const slice = createSlice({
  name: 'article',
  initialState: articlelInitialState,
  reducers: {
    getLoad: (state, { payload }) => {
      state.id = payload.id
      state.title = payload.title
      state.createdAt = payload.created_at
      state.announcement = payload.announcement
      state.content = payload.content
      state.img = payload.img
      state.tags = payload.tags
      state.moder = payload.moder
    }
  }
})

const { getLoad } = slice.actions

export const loadArticle = (articleId) => async (dispatch) => {
  const data = article.find(({ id }) => id === articleId)
  dispatch(getLoad(data))
}

export const selectArticle = (state) => state.article

export default slice.reducer
