import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const getLocationKey = (location) =>
  location.state && location.state.key ? location.state.key : null

export const slice = createSlice({
  name: 'questionsHistory',
  initialState,
  reducers: {
    setHistory: (state, { payload }) => {
      state[getLocationKey(payload.prevLocation)] = {
        openQuestions: payload.openQuestions || {},
        locationKey: getLocationKey(payload.prevLocation),
        href: payload.prevLocation.href
      }
    }
  }
})

export const { setHistory } = slice.actions

export const selectOpenQuestions = (state) => state.questionsHistory
export default slice.reducer
