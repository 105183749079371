import { createSlice } from '@reduxjs/toolkit'

import { webinars } from '../../../static/db.json'

const webinarslInitialState = {
  cards: [],
  count: 0
}

export const slice = createSlice({
  name: 'webinars',
  initialState: webinarslInitialState,
  reducers: {
    getLoad: (state, { payload }) => {
      state.cards = payload.webinars
      state.count = payload.count
    }
  }
})

const { getLoad } = slice.actions

export const loadWebinars =
  ({ page = 1, limit = 9 }) =>
  async (dispatch) => {
    const data = webinars.filter((webinar, i) => {
      if (i < page * limit && i >= (page - 1) * limit) {
        return webinar
      }
    })
    dispatch(getLoad(data))
  }

export const selectWebinars = (state) => state.webinars

export default slice.reducer
