import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'

import articleReducer from './reducers/articleSlice'
import articlesReducer from './reducers/articlesSlice'
import dataReducer from './reducers/dataSlice'
import deviceReducer from './reducers/deviceSlice'
import errorHandleMiddleware from './reducers/middlewares/errorHandleMiddleware'
import openQuestionsReducer from './reducers/openQuestionSlice'
import partnersReducer from './reducers/partnersSlice'
import policyReducer from './reducers/policySlice'
import routingReducer from './reducers/routingSlice'
import scrollReducer from './reducers/scrollHistorySlice'
import webinarsReducer from './reducers/webinarsSlice'

export default configureStore({
  reducer: {
    history: scrollReducer,
    questionsHistory: openQuestionsReducer,
    data: dataReducer,
    partners: partnersReducer,
    device: deviceReducer,
    policy: policyReducer,
    webinars: webinarsReducer,
    articles: articlesReducer,
    article: articleReducer,
    routing: routingReducer
  },
  middleware: [errorHandleMiddleware, ...getDefaultMiddleware()],
  devTools: process.env.NODE_ENV !== 'production'
})
