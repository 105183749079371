import { createSlice, current } from '@reduxjs/toolkit'

const initialState = {}

const getLocationKey = (location) =>
  location.state && location.state.key ? location.state.key : null

export const slice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    setHistory: (state, { payload }) => {
      if (!getLocationKey(payload.prevLocation)) {
        return current(state)
      }
      state[getLocationKey(payload.prevLocation)] = {
        scrollTo: payload.scroll || null,
        locationKey: getLocationKey(payload.prevLocation)
      }
    }
  }
})

export const { setHistory } = slice.actions

export const selectScrollHistory = (state) => state.history
export default slice.reducer
