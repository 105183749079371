import React from 'react'
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink
} from '@apollo/client'
import { WEBSITE_SCHEMA } from '../var'
import { cachePolicy } from './common'
import { setContext } from '@apollo/client/link/context'

const httpLink = createHttpLink({ uri: WEBSITE_SCHEMA })

const authLink = setContext((_, { headers }) => {
  const token = sessionStorage.getItem('auth_token')

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

export const apolloClient = new ApolloClient({
  ssrMode: true,
  // eslint-disable-next-line unicorn/prefer-spread
  link: authLink.concat(httpLink),
  uri: WEBSITE_SCHEMA,
  cache: new InMemoryCache(),
  ...cachePolicy
})

export const ApolloClientProvider = (props) => (
  <ApolloProvider client={apolloClient}>{props.children}</ApolloProvider>
)
