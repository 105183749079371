import { createSlice } from '@reduxjs/toolkit'

const deviceInitialState = {
  width: null
}

export const slice = createSlice({
  name: 'device',
  initialState: deviceInitialState,
  reducers: {
    setDeviceWindowProps: (state, { payload }) => {
      state.width = payload.width
    }
  }
})

export const { setDeviceWindowProps } = slice.actions

export const selectDeviceSize = (state) => state.device.width
export const selectDeviceSizeIsMobile = (state) => state.device.width < 767

export default slice.reducer
